import React from "react";
// @ts-ignore
import { AccordionItem as AccessibleAccordionItem } from "react-accessible-accordion";
import { ListItem } from "../../List";
import { styled } from "../../../styles";

const AccordeonListItem = styled(ListItem).attrs({})``;

export const AccordeonItem: React.FC<{ expanded?: boolean }> = ({
  children,
  ...props
}) => {
  return (
    <AccordeonListItem>
      <AccessibleAccordionItem {...props}>{children}</AccessibleAccordionItem>
    </AccordeonListItem>
  );
};
