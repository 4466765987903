import { styled, media, fonts, white, black } from "../../styles";

export const TileTitle = styled.h3`
  flex-grow: unset;
  overflow: visible;

  padding: 0;
  line-height: 1.15px;
  letter-spacing: 0;
  margin: 0.4rem 0 1.2rem 10px;
  font-size: 1.5rem;

  ${media.tablet} {
    font-size: 2rem;
  }
`;

// Text
export const TileFact = styled.span`
  flex-grow: unset;
  width: 100%;
  display: block;
  overflow: visible;
  font-size: 12px;
  letter-spacing: 0px;
  margin-bottom: 0;
  margin-left: 10px;
  padding: 0;
  font-family: ${fonts.nittiGrotesk};

  ${media.tablet} {
    font-size: 14px;
  }
`;

export const Glass = styled.span`
  display: inline;
  background-color: ${white};
  color: ${black};

  padding: 0.2rem 0 0.3rem 0;
  margin: 0;
  line-height: 1.5;
  text-indent: 0.5rem;

  vertical-align: middle;

  box-shadow: 0.5rem 0 0 ${white}, -0.5rem 0 0 ${white};
`;
