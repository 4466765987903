import { Link, LinkProps } from "../Link/Link";
import { styled, media, black, white } from "../../styles";

export interface CategoryProps extends LinkProps {}

export const Category = styled(Link)<CategoryProps>`
  display: block;
  white-space: nowrap;
  background-color: ${black};
  color: ${white};

  padding: 0.4rem 0.9rem 0.5rem 0.9rem;
  cursor: pointer;

  font-size: 16px;

  ${media.desktop} {
    font-size: 20px;
  }
`;
