import React from "react";
// @ts-ignore
import { AccordionItemTitle as AccessibleAccordionItemTitle } from "react-accessible-accordion";
import { styled, media, fonts, pink } from "../../../styles";

const AccordeonHeading = styled.h4`
  font-family: ${fonts.vario};
  font-size: 17px;
  text-decoration: none;
  vertical-align: baseline;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  width: 100%;
  outline: none;

  cursor: pointer;

  ${media.tablet} {
    font-size: 23px;
    margin-bottom: 2rem;
  }

  &:hover {
    color: ${pink};
    animation: wahnsinn 0.3s linear 0s infinite;
    outline: none;
  }

  &:before {
    content: ">";
    font-family: ${fonts.vario};
    text-decoration: none;
    margin-left: -1em;
    color: ${pink};
    cursor: pointer;
    vertical-align: baseline;
    text-transform: uppercase;

    &:hover {
      color: ${pink};
      animation: wahnsinn 0.3s linear 0s infinite;
    }
  }
`;

export const AccordeonTitle: React.FC<{}> = ({ children }) => {
  return (
    <AccessibleAccordionItemTitle>
      <AccordeonHeading>{children}</AccordeonHeading>
    </AccessibleAccordionItemTitle>
  );
};
