import React from "react";
// @ts-ignore
import { AccordionItemBody as AccessibleAccordionItemBody } from "react-accessible-accordion";

export const AccordeonBody: React.FC<{}> = ({ children, ...props }) => {
  return (
    <AccessibleAccordionItemBody {...props}>
      <p>{children}</p>
    </AccessibleAccordionItemBody>
  );
};
