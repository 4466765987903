import React from "react";
import { styled, media } from "../../styles";

export interface TitleImageProps {
  title: string;
  src: string;
  className?: string;
}

const TitleImageContainer: React.FC<TitleImageProps> = ({
  src,
  title,
  className
}) => (
  <TitleImageHeading className={className}>
    <img src={src} alt={title} />
  </TitleImageHeading>
);

const TitleImageHeading = styled.h1`
  padding: 0 16px;
  width: 100%;
  margin-bottom: 0;

  ${media.tablet} {
    width: 50%;
  }
`;

export const TitleImage = styled(TitleImageContainer)``;
