import React from "react";
// @ts-ignore
import { Accordion as AccessibleAccordeon } from "react-accessible-accordion";
import { styled } from "../../styles";
import { List } from "../List";

const StyledAccessibleAccordeon = styled(AccessibleAccordeon)`
  margin-left: -2rem;
  padding-left: 2rem;

  .accordion__title {
    outline: none;
  }

  .accordion__body {
    overflow: hidden;
    margin-top: 1rem;
    transition: all 0.5s ease;
    height: auto;

    &[aria-hidden="false"] {
      opacity: 1;
    }

    &[aria-hidden="true"] {
      opacity: 0;
      height: 0;
    }
  }
`;

const AccordeonList = styled(List).attrs({})``;

export const Accordeon: React.FC<{}> = ({ children, ...props }) => {
  return (
    <StyledAccessibleAccordeon {...props}>
      <AccordeonList>{children}</AccordeonList>
    </StyledAccessibleAccordeon>
  );
};

export * from "./components/AccordeonItem";
export * from "./components/AccordeonTitle";
export * from "./components/AccordeonBody";
