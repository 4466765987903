import { styled, fontFamily, fonts, FontFamilyProps } from "../../styles";

export const Icon = styled.span.attrs({
  fontFamily: fonts.vario,
  ariaHidden: "true"
})<FontFamilyProps>`
  text-transform: uppercase;
  font-size: 40px;
  ${fontFamily}

  text-transform: uppercase;
  font-variation-settings: "wght" 520;
`;
